
function getFGCCountForUSDWithDiscount(usdCount = 0) {
    // get amount for current usd
    return usdCount * getUSDRatio();
}

function getUSDCountForFGCWithoutDiscount(fgcCount = 0) {
    // how many usd required to buy x fgc
    return fgcCount / getUSDRatio();
}

/*
returns fgc for usd
*/
function getFGCCountForUSD(usdCount = 0) {

    // get amount for current usd
    const initialTokenCount = usdCount * getUSDRatio();

    const calculatedRatio = getRateRatioForTokenCount(initialTokenCount);

    const newTokenCount = usdCount / calculatedRatio;

    // get token count after discount, maybe it falls in a different tier now
    const newCalculatedRatio = getRateRatioForTokenCount(newTokenCount);

    const finalTokenCount = usdCount / newCalculatedRatio;

    return finalTokenCount;
}

function getUSDRatio() { // how many usd for one fgc
    return 1;
}

/**
 * get rate ratio for a specific token count
 * @param tokenCount 
 * @returns 
 */
function getRateRatioForTokenCount(tokenCount: number) {
    // get current month 
    const monthIndex = (new Date()).getMonth(); // feb is 1 - 1 = 0 index
    // const monthIndex = 1; 
    const USD_RATIO = getUSDRatio();
    let calculatedRatio = USD_RATIO;
    // const monthMap = [
    //     {
    //         moreThan20000: 0.91,
    //         moreThan5000: 0.93,
    //         moreThan1000: 0.94,
    //         moreThan500: 0.96,
    //         moreThan100: 0.97,
    //         moreThan50: 0.98,
    //         moreThan0: 0.99,
    //     },
    //     {
    //         moreThan20000: 0.91,
    //         moreThan5000: 0.93,
    //         moreThan1000: 0.94,
    //         moreThan500: 0.96,
    //         moreThan100: 0.97,
    //         moreThan50: 0.98,
    //         moreThan0: 0.99,
    //     },
    //     {
    //         moreThan20000: 0.91,
    //         moreThan5000: 0.93,
    //         moreThan1000: 0.94,
    //         moreThan500: 0.96,
    //         moreThan100: 0.97,
    //         moreThan50: 0.98,
    //         moreThan0: 0.99,
    //     },
    //     {
    //         moreThan20000: 0.91,
    //         moreThan5000: 0.93,
    //         moreThan1000: 0.94,
    //         moreThan500: 0.96,
    //         moreThan100: 0.97,
    //         moreThan50: 0.98,
    //         moreThan0: 0.99,
    //     },
    //     { // june
    //         moreThan20000: 0.91,
    //         moreThan5000: 0.93,
    //         moreThan1000: 0.94,
    //         moreThan500: 0.96,
    //         moreThan100: 0.97,
    //         moreThan50: 0.98,
    //         moreThan0: 0.99,
    //     },
    //     { // Jul
    //         moreThan20000: 0.9700,
    //         moreThan5000: 0.9800,
    //         moreThan1000: 0.9850,
    //         moreThan500: 0.9880,
    //         moreThan100: 0.9900,
    //         moreThan50: 0.9930,
    //         moreThan0: 0.9950,
    //     },
    //     { // Aug
    //         moreThan20000: 0.9850,
    //         moreThan5000: 0.9900,
    //         moreThan1000: 0.9925,
    //         moreThan500: 0.9940,
    //         moreThan100: 0.9950,
    //         moreThan50: 0.9965,
    //         moreThan0: 0.9975,
    //     },
    //     { // Sept
    //         moreThan20000: 0.9990,
    //         moreThan5000: 0.9986,
    //         moreThan1000: 0.9980,
    //         moreThan500: 0.9976,
    //         moreThan100: 0.9980,
    //         moreThan50: 0.9986,
    //         moreThan0: 0.9990,
    //     },
    //     { // Oct
    //         moreThan20000: 0.9990,
    //         moreThan5000: 0.9986,
    //         moreThan1000: 0.9980,
    //         moreThan500: 0.9976,
    //         moreThan100: 0.9980,
    //         moreThan50: 0.9986,
    //         moreThan0: 0.9990,
    //     },
    //     { // Nov
    //         moreThan20000: 0.9990,
    //         moreThan5000: 0.9986,
    //         moreThan1000: 0.9980,
    //         moreThan500: 0.9976,
    //         moreThan100: 0.9980,
    //         moreThan50: 0.9986,
    //         moreThan0: 0.9990,
    //     },
    //     { // Dec
    //         moreThan20000: 0.9990,
    //         moreThan5000: 0.9986,
    //         moreThan1000: 0.9980,
    //         moreThan500: 0.9976,
    //         moreThan100: 0.9980,
    //         moreThan50: 0.9986,
    //         moreThan0: 0.9990,
    //     },
    // ];

    const monthMap = [
        { // Jan
            moreThan20000: 0.9990,
            moreThan5000: 0.9986,
            moreThan1000: 0.9980,
            moreThan500: 0.9976,
            moreThan100: 0.9980,
            moreThan50: 0.9986,
            moreThan0: 0.9990,
        },
        { // Feb
            moreThan20000: 0.9990,
            moreThan5000: 0.9986,
            moreThan1000: 0.9980,
            moreThan500: 0.9976,
            moreThan100: 0.9980,
            moreThan50: 0.9986,
            moreThan0: 0.9990,
        },
        { // Mar
            moreThan20000: 0.9990,
            moreThan5000: 0.9986,
            moreThan1000: 0.9980,
            moreThan500: 0.9976,
            moreThan100: 0.9980,
            moreThan50: 0.9986,
            moreThan0: 0.9990,
        },
        { // Apr
            moreThan20000: 0.9990,
            moreThan5000: 0.9986,
            moreThan1000: 0.9980,
            moreThan500: 0.9976,
            moreThan100: 0.9980,
            moreThan50: 0.9986,
            moreThan0: 0.9990,
        },
        { // May
            moreThan20000: 0.9990,
            moreThan5000: 0.9986,
            moreThan1000: 0.9980,
            moreThan500: 0.9976,
            moreThan100: 0.9980,
            moreThan50: 0.9986,
            moreThan0: 0.9990,
        },
        { // Jun
            moreThan20000: 0.9990,
            moreThan5000: 0.9986,
            moreThan1000: 0.9980,
            moreThan500: 0.9976,
            moreThan100: 0.9980,
            moreThan50: 0.9986,
            moreThan0: 0.9990,
        },
    ]

    // get usd price for the current fgc count
    if (tokenCount > 20000) {
        calculatedRatio = monthMap[monthIndex].moreThan20000;
    } else if (tokenCount > 5000) {
        calculatedRatio = monthMap[monthIndex].moreThan5000;
    } else if (tokenCount > 1000) {
        calculatedRatio = monthMap[monthIndex].moreThan1000;
    } else if (tokenCount > 500) {
        calculatedRatio = monthMap[monthIndex].moreThan500;
    } else if (tokenCount > 100) {
        calculatedRatio = monthMap[monthIndex].moreThan100;
    } else if (tokenCount > 50) {
        calculatedRatio = monthMap[monthIndex].moreThan50;
    } else if (tokenCount > 0) {
        calculatedRatio = monthMap[monthIndex].moreThan0;
    }

    return calculatedRatio;
}

function getAdditionalBonusTokenCount(tokenCount = 0) {
    const monthIndex = (new Date()).getMonth(); //- 1;  feb is 1 - 1 = 0 index
    let monthMaxBonus = 0;
    const monthMap = [
        // 3000,
        // 3000,
        // 3000,
        // 3000,
        // 3000,
        // 3000, // july
        // 2500, // Aug
        // 2000, // Sept
        // 2000, // Oct
        // 2000, // Nov
        // 2000, // Dec
        2000, // Jan
        2000, // Feb
        2000, // Mar
        2000, // Apr
        2000, // May
        2000, // Jun
    ];
    // get usd price for the current fgc count
    monthMaxBonus = monthMap[monthIndex];

    const additionalFGCCount = Math.floor((tokenCount / 50000) * monthMaxBonus);
    return additionalFGCCount;
}

export {
    getUSDRatio,
    getFGCCountForUSD,
    getRateRatioForTokenCount,
    getAdditionalBonusTokenCount,
    getFGCCountForUSDWithDiscount,
    getUSDCountForFGCWithoutDiscount,
}
