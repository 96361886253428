import moment from 'moment';
import React, { useState } from 'react';
import RFacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { httpErrorToast } from '../../helpers/ui.helper';
import { useQuery } from '../../hooks/useQuery.hook';
import { loginWithFacebook } from '../../services/auth.service';
import { createSession } from '../../store/auth.store';

function FacebookLoginComponent({
    setUser
}: any) {

    const query = useQuery();
    const referralCode = query.get("ref");

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const onFacebookResponse = async (response: any) => {
        // console.log("fbresponse", response);
        // get access token
        const { accessToken } = response;
        console.log("access", response);
        // login using this access token
        setIsLoading(true);
        // login using this access token
        try {
            const response = await loginWithFacebook(accessToken, referralCode ?? "");
            if (response.message) {
                // all correct, start session
                toast.success(response.message);
                const userObject = {
                    ...response.user,
                    token: (response as any).token,
                };
                setUser(userObject);
                localStorage.setItem('fgc.user', JSON.stringify(userObject));
                localStorage.setItem('fgc.userSession', JSON.stringify({ session: 'active', lastActive: moment() }));
                navigate('/dashboard')
            }
        } catch (error: any) {
            setIsLoading(false);
            httpErrorToast(error);
            console.log("Facebook login error", error);
            // axiosSetError(error);
        }
    }

    return (
        <RFacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID ?? ""}
            fields="name,email,picture,mobile"
            disableMobileRedirect
            render={(props) => {
                return (
                    <a href="#"
                        {...props} className="btn btn-outline btn-dark btn-facebook btn-block"><em className="fab fa-facebook-f" /><span>Facebook</span></a>
                );
            }}
            callback={onFacebookResponse}
        />
    );
}
const dtp = {
    setUser: createSession
}

export const FacebookLogin = connect(() => ({}), dtp)(FacebookLoginComponent);
