import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { readableNum } from '../../helpers/ui.helper';
import { StoreState } from '../../types/store-state.type';
import moment from 'moment';

function TokenSalesProgressCardComponent({
    totalTokenSale,
}: {
    totalTokenSale: number
}) {

    useEffect(() => {
        startCountDown();
    }, []);

    const countDownDate = new Date("June 30, 2024 23:59:59").getTime();
    const [days, setDays] = useState<number>(0);
    const [hours, setHours] = useState<number>(0);
    const [minutes, setMinutes] = useState<number>(0);
    const [seconds, setSeconds] = useState<number>(0);
    const [isExpired, setIsExpired] = useState<boolean>(false);

    const startCountDown = () => {
        const x = setInterval(() => {

            // Find the distance between the countdown date and now

            const distance = countDownDate - new Date().getTime();

            // Time calculations for days, hours, minutes and seconds

            setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
            setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
            setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
            setSeconds(Math.floor((distance % (1000 * 60)) / 1000));

            // If the count down is finished, show expired

            if (distance < 0) {
                clearInterval(x);
                setDays(0);
                setHours(0);
                setMinutes(0);
                setSeconds(0);
                setIsExpired(true);
            }
        }, 1000);

    };

    const totalTokenForSale = 10000000;

    const percentageSold = ((totalTokenSale * 100) / totalTokenForSale).toFixed(2);

    return (
        <div className="token-sales card">
            <div className="card-innr">
                <div className="card-head">
                    <h4 className="card-title">Token Sales Progress</h4>
                </div>
                {percentageSold}
                <ul className="progress-info">
                    <li><span>Raised</span> {readableNum(totalTokenSale)} FGC</li>
                    <li className="text-right"><span>TOTAL</span> {readableNum(totalTokenForSale, 0)} FGC</li>
                </ul>
                <div className="progress-bar">
                    <div className="progress-hcap" data-percent={80} style={{ width: '80%' }}>
                        {/* <div><span>8,000,000</span></div> */}
                    </div>
                    <div className="progress-scap" data-percent={40} style={{ width: '40%' }}>
                        {/* <div><span>4,000,000</span></div> */}
                    </div>
                    <div className="progress-percent" data-percent={percentageSold} style={{ width: percentageSold + "%" }} />
                </div>
                <span className="card-sub-title mgb-0-5x">Sales END IN</span>
                <div className="countdown-clock" data-date="2019/04/05">
                    <div>
                        <span className="countdown-time countdown-time-first">{days}</span>
                        <span className="countdown-text">Day</span>
                    </div>
                    <div>
                        <span className="countdown-time">{hours}</span>
                        <span className="countdown-text">Hour</span>
                    </div>
                    <div>
                        <span className="countdown-time">{minutes}</span>
                        <span className="countdown-text">Min</span>
                    </div>
                    <div>
                        <span className="countdown-time countdown-time-last">{seconds}</span>
                        <span className="countdown-text">Sec</span>
                    </div>
                </div>
                {isExpired ?
                    <div className='countdown-time pt-4 text-center text-danger'>
                        SALE ENDED
                    </div>
                    :
                    <></>
                }
            </div>
        </div>
    );
}

const stp = (state: StoreState) => ({
    totalTokenSale: state.totalTokenSale,
})

const TokenSalesProgressCard = connect(stp)(TokenSalesProgressCardComponent);

export { TokenSalesProgressCard };
