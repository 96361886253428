import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BuyTokenCheckoutButton } from '../../components/buy-token-checkout.button';
import { ContributionCard } from '../../components/contribution-card.component';
import { GetPayAddressModal } from '../../components/modals/get-pay-address.modal';
import { PayConfirmModal } from '../../components/modals/pay-confirm.modal';
import { PayReviewModal } from '../../components/modals/pay-review.modal';
import { TokenSalesProgressCard } from '../../components/token-sales-progress-card.component';
import { getAdditionalBonusTokenCount, getFGCCountForUSD, getFGCCountForUSDWithDiscount, getRateRatioForTokenCount, getUSDCountForFGCWithoutDiscount, getUSDRatio } from '../../helpers/currency.helper';
import { readableNum } from '../../helpers/ui.helper';
import { getExchangeRateFromServer } from '../../store/exchange-rate.store';
import { getWalletDetails } from '../../store/wallet-details.store';
import { GetWalletAdressModal } from './get-wallet-address.modal';
import { PayFromWalletButton } from './pay-from-wallet.button';
import { TokenCurrencyChooser } from './token-currency-chooser';
import { BankTransferBtn } from './bank-transfer-btn';
import { MobileOnlyPageHeading } from '../../components/mobile-only-page-heading';
import { Banner } from '../../components/banner';
import { WesternUnionBtn } from './western-union-btn';

function BuyTokenPageComponent({
    // state
    user,
    walletDetails,
    exchangeRate,
    // disaptch
    getWalletDetails,
}: any) {
    const bitcoinPrice = exchangeRate.bitcoin.usd;
    const ethereumPrice = exchangeRate.ethereum.usd;

    const [selectedCurrency, setSelectedCurrency] = useState("usd");


    const [selectedAmount, setSelectedAmount] = useState<number | string>(1); // $s
    const [selectedFGCAmount, setSelectedFGCAmount] = useState<number | string>("");

    const payableDollars = parseInt(selectedAmount.toString()) ? parseInt(selectedAmount.toString()) : 0;
    const payableFGCCount = parseInt(selectedFGCAmount.toString()) ? parseInt(selectedFGCAmount.toString()) : 0;

    // transaction related
    const [orderId, setOrderId] = useState("");
    const [orderCurrency, setOrderCurrency] = useState("");

    // get balance from wallet details
    const hasAmountInWallet = walletDetails[selectedCurrency] >= selectedAmount;
    const missingAmount = parseFloat(selectedAmount.toString()) - walletDetails[selectedCurrency];

    // load additional data according to user
    const userId = user?.id;
    useEffect(() => {
        if (userId) {
            getWalletDetails();
        }
    }, [userId]);

    const fgcCountforUSD = getFGCCountForUSD(payableDollars);

    // get the conversion rates
    useEffect(() => {
        fetchPrices();
        // also update fgc count
        setSelectedFGCAmount(fgcCountforUSD);
    }, []);
    const fetchPrices = async () => {
        await getExchangeRateFromServer();
    };

    const fgcCoinsFromDiscount = fgcCountforUSD - getFGCCountForUSDWithDiscount(payableDollars); // amount of discount due to sale
    const additionalFGCCount = getAdditionalBonusTokenCount(fgcCountforUSD);
    const totalAmountFGC = fgcCountforUSD + additionalFGCCount;

    const selectedCurrencyPrice = selectedCurrency === "eth" ? ethereumPrice : bitcoinPrice;

    const updateFGCCountForDollars = (value: string) => {
        const usdCount = parseInt(value.toString()) ? parseInt(value.toString()) : 0;
        setSelectedFGCAmount(getFGCCountForUSD(usdCount));
    }
    const updateUSDCountForFGC = (value: string) => {
        const fgcCount = parseInt(value.toString()) ? parseInt(value.toString()) : 0;
        setSelectedAmount(getUSDCountForFGCWithoutDiscount(fgcCount));
    }

    return (
        <div className="BuyTokenPage">
            <div className="page-user">
                <div className="page-content">
                    <div className="container">
                        <Banner images={['/images/buy_ico_1.jpg', '/images/dashboard_2.png', '/images/buy_ico_3.jpg']} />
                        <div className="gaps-3x" />
                        <div className="row">
                            <MobileOnlyPageHeading title="Buy" />
                            <div className='col-md-12 d-lg-none'>
                                <ContributionCard />
                            </div>
                            <div className="main-content col-lg-8">
                                {/* {
                                    !user.receivingWalletAddress ? (
                                        <div className="d-lg-none">
                                            <a href="#" data-toggle="modal" data-target="#add-wallet" className="btn btn-danger btn-xl btn-between w-100 mgb-1-5x">Add your wallet address before buy <em className="ti ti-arrow-right" /></a>
                                            <div className="gaps-1x mgb-0-5x d-lg-none d-none d-sm-block" />
                                        </div>
                                    ) : <></>
                                } */}
                                <div className="content-area card">
                                    <div className="card-innr">
                                        {/* <div className="card-head">
                                            <h4 className="card-title">Wallet balance</h4>
                                        </div>
                                        <TokenCurrencyChooser
                                            selectedCurrency={selectedCurrency}
                                            setSelectedCurrency={setSelectedCurrency} /> */}
                                        <div className="card-head">
                                            <span className="card-sub-title text-primary font-mid">Step 1</span>
                                            <h4 className="card-title">Amount to Buy</h4>
                                        </div>
                                        <div className="card-text">
                                            <p className='mb-1'>Enter amount, you would like to buy</p>
                                        </div>
                                        {/* <TokenCurrencyChooser
                                            selectedCurrency={selectedCurrency}
                                            setSelectedCurrency={setSelectedCurrency} />
                                        <div className="card-head">
                                            <span className="card-sub-title text-primary font-mid">Step 2</span>
                                            <h4 className="card-title">Amount to buy</h4>
                                        </div>
                                        <div className="card-text">
                                            <p>Enter your amount, you would like to contribute and calculate the amount of token you will received. The calculator helps to convert required currency to tokens.</p>
                                        </div> */}
                                        <div className="token-contribute">
                                            <div className="token-calc justify-content-between justify-content-sm-start">
                                                <div className="token-pay-amount">
                                                    <input
                                                        id="token-base-amount"
                                                        className="input-bordered input-with-hint green-input"
                                                        type="text"
                                                        value={selectedAmount}
                                                        onChange={e => {
                                                            setSelectedAmount(e.target.value);
                                                            // update fgc count in relation
                                                            updateFGCCountForDollars(e.target.value);
                                                        }}
                                                        pattern="\d" />
                                                    <div className="token-pay-currency">
                                                        <span className="input-hint input-hint-sap">{selectedCurrency.toUpperCase()}</span>
                                                    </div>
                                                </div>
                                                <div className="token-eq-sign">=</div>
                                                <div className="token-received">
                                                    <div className="token-pay-amount">
                                                        <input
                                                            id="token-base-amount"
                                                            className="input-bordered input-with-hint yellow-input"
                                                            type="text"
                                                            value={selectedFGCAmount}
                                                            onChange={e => {
                                                                setSelectedFGCAmount(e.target.value);
                                                                // update dollars in relation
                                                                updateUSDCountForFGC(e.target.value);
                                                            }}
                                                            pattern="\d" />
                                                        <div className="token-pay-currency">
                                                            <span className="input-hint input-hint-sap">FGC</span>
                                                        </div>
                                                        {/* <h5 className="token-amount">{fgcCountforUSD.toFixed(4)}</h5> */}
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                !selectedAmount || selectedAmount < 0.01 ? (
                                                    <div className="token-calc-note note note-plane">
                                                        <em className="fas fa-times-circle text-danger" />
                                                        <span className="note-text text-light">0.01 USD minimum contribution required.</span>
                                                    </div>
                                                ) : <></>
                                            }
                                        </div>
                                        {/* <div className="token-bonus-ui">
                                            <div className="bonus-bar">
                                                <div className="bonus-base">
                                                    <span className="bonus-base-title">Bonus</span>
                                                    <span className="bonus-base-amount">On Sale</span>
                                                    <span className="bonus-base-percent">20%</span>
                                                </div>
                                                <div className="bonus-extra">
                                                    <div className="bonus-extra-item active" style={{ width: "10%" }}>
                                                        <span className="bonus-extra-amount">0.5 ETH</span>
                                                        <span className="bonus-extra-percent">10%</span>
                                                    </div>
                                                    <div className="bonus-extra-item active" style={{ width: "20%" }}>
                                                        <span className="bonus-extra-amount">1 ETH</span>
                                                        <span className="bonus-extra-percent">30%</span>
                                                    </div>
                                                    <div className="bonus-extra-item active" style={{ width: "20%" }}>
                                                        <span className="bonus-extra-amount">5 ETH</span>
                                                        <span className="bonus-extra-percent">50%</span>
                                                    </div>
                                                    <div className="bonus-extra-item" style={{ width: "20%" }}>
                                                        <span className="bonus-extra-amount">10 ETH</span>
                                                        <span className="bonus-extra-percent">70%</span>
                                                    </div>
                                                    <div className="bonus-extra-item" style={{ width: "30%" }}>
                                                        <span className="bonus-extra-amount">20 ETH</span>
                                                        <span className="bonus-extra-percent">100%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="token-overview-wrap">
                                            <div className="token-overview">
                                                <div className='discount-and-bonus d-lg-none'>
                                                    <em className='fa fa-star'></em>
                                                    Discount & Bonus
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4 col-sm-12">
                                                        <div className="token-bonus token-bonus-sale">
                                                            <span className="token-overview-title">+ Sale discount token</span>
                                                            <span className="token-overview-value bonus-on-sale">{readableNum(fgcCoinsFromDiscount)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-12">
                                                        <div className="token-bonus token-bonus-amount">
                                                            <span className="token-overview-title">+ Amount Bonus</span>
                                                            <span className="token-overview-value bonus-on-amount">{readableNum(additionalFGCCount)}</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-sm-12">
                                                        <div className="token-total">
                                                            <span className="token-overview-title font-bold">Total FGC</span>
                                                            <span className="token-overview-value token-total-amount text-primary">{readableNum(totalAmountFGC)}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="note note-plane note-danger note-sm pdt-1x pl-0">
                                                <p>Your Contribution will be calculated based on exchange rate at the moment your transaction is confirm.</p>
                                            </div> */}
                                        </div>
                                        <div className="card-head">
                                            <span className="card-sub-title text-primary font-mid">Step 2</span>
                                            <h4 className="card-title">Choose Payment Method</h4>
                                        </div>
                                        <div className="card-text">
                                            {
                                                hasAmountInWallet ? "" : <p className='text-justify mb-2'>To purchase FGC ICO tokens, please deposit correct funds amount. You can transfer funds directly to our bank account, pay via Paypal or make a card payment. Once funds are received, we shall email you to confirm the successful deposit and purchase.</p>
                                            }
                                        </div>
                                        <div className='row'>
                                            <div className="col-md-6">
                                                {
                                                    hasAmountInWallet ? (
                                                        <PayFromWalletButton
                                                            selectedCurrency={selectedCurrency}
                                                            selectedAmount={parseFloat(selectedAmount.toString())} />
                                                    ) : (
                                                        <>
                                                            <div className="my-2"></div>
                                                            <BuyTokenCheckoutButton selectedAmount={missingAmount} />
                                                            <div className="pay-button-sap my-2">or</div>
                                                            <a href="#" data-toggle="modal" data-target="#get-pay-address" className="btn btn-primary btn-between w-100">
                                                                <div className="d-flex justify-content-between">
                                                                    <span>Bitcoin or Etherum</span>
                                                                    <span><img src="./images/btheth.png" style={{
                                                                        height: '65px',
                                                                        position: 'absolute',
                                                                        top: '-14px',
                                                                        right: '-20px'
                                                                    }} alt="" /></span>
                                                                </div>
                                                            </a>
                                                        </>
                                                    )
                                                }
                                                <div className="pay-button-sap my-2">or</div>
                                                <BankTransferBtn />
                                                <div className="pay-button-sap my-2">or</div>
                                                <WesternUnionBtn />
                                            </div>
                                        </div>
                                        <div className="row d-lg-none">
                                            <div className="col-md-6 text-center">
                                                <img src="/images/money_back_guarantee.png" className='mt-3' alt="" />
                                            </div>
                                        </div>
                                        <div className="pay-notes">
                                            <div className="note note-plane note-light note-md font-italic">
                                                <em className="fas fa-info-circle" />
                                                <p>Tokens will appear in your account after payment is successfully made and approved by our team.
                                                    <br className="d-none d-lg-block" /> Please note, FGC ICO tokens will be distributed at the end of ICO Token Promotion.</p>
                                            </div>
                                        </div>
                                    </div> {/* .card-innr */}
                                </div> {/* .content-area */}
                            </div>{/* .col */}
                            <div className="aside sidebar-right col-lg-4">
                                {/* {
                                    !user.receivingWalletAddress ? (
                                        <div className="d-none d-lg-block">
                                            <a href="#" data-toggle="modal" data-target="#add-wallet" className="btn btn-danger btn-xl btn-between w-100">Add your wallet address before buy <em className="ti ti-arrow-right" /></a>
                                            <div className="gaps-3x" />
                                        </div>
                                    ) : <></>
                                } */}
                                <div className='d-none d-lg-block'>
                                    <ContributionCard />
                                </div>
                            </div>{/* .col */}
                        </div>{/* .container */}
                    </div>{/* .container */}
                </div>
                <GetWalletAdressModal modalId="add-wallet" />
                {/* Modal End */}
                <GetPayAddressModal
                    modalId="get-pay-address"
                    setOrderId={setOrderId}
                    setOrderCurrency={setOrderCurrency} />
                {/* Modal End */}
                <PayConfirmModal
                    modalId="pay-confirm"
                    orderId={orderId}
                    orderCurrency={orderCurrency} />
                {/* Modal End */}
                {/* not being used right now */}
                <div className="modal fade" id="pay-online" tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-md modal-dialog-centered">
                        <div className="modal-content pb-0">
                            <div className="popup-body">
                                <h4 className="popup-title">Buy Tokens and Payment</h4>
                                <p className="lead">To receiving <strong>18,750 FGC</strong> tokens including <strong>bonus 1,540 FGC</strong> require payment amount of <strong>1.0 ETH</strong>.</p>
                                <p>You can choose any of following payment method to make your payment. The tokens balance will appear in your account after successfull payment.</p>
                                <h5 className="mgt-1-5x font-mid">Select payment method:</h5>
                                <ul className="pay-list guttar-20px">
                                    <li className="pay-item">
                                        <input type="radio" className="pay-check" name="pay-option" id="pay-coin" />
                                        <label className="pay-check-label" htmlFor="pay-coin"><img src="images/pay-a.png" alt="pay-logo" /></label>
                                    </li>
                                    <li className="pay-item">
                                        <input type="radio" className="pay-check" name="pay-option" id="pay-coinpay" />
                                        <label className="pay-check-label" htmlFor="pay-coinpay"><img src="images/pay-b.png" alt="pay-logo" /></label>
                                    </li>
                                    <li className="pay-item">
                                        <input type="radio" className="pay-check" name="pay-option" id="pay-paypal" />
                                        <label className="pay-check-label" htmlFor="pay-paypal"><img src="images/pay-c.png" alt="pay-logo" /></label>
                                    </li>
                                </ul>
                                <span className="text-light font-italic mgb-2x"><small>* Payment gateway company may charge you a processing fees.</small></span>
                                <div className="pdb-2-5x pdt-1-5x">
                                    <input type="checkbox" className="input-checkbox input-checkbox-md" id="agree-term-3" />
                                    <label htmlFor="agree-term-3">I hereby agree to the <strong>token purchase aggrement &amp; token sale term</strong>.</label>
                                </div>
                                <ul className="d-flex flex-wrap align-items-center guttar-30px">
                                    <li><a href="#" data-dismiss="modal" data-toggle="modal" data-target="#pay-review" className="btn btn-primary">Buy Tokens &amp; Process to Pay <em className="ti ti-arrow-right mgl-2x" /></a></li>
                                    <li className="pdt-1x pdb-1x"><a href="#" data-dismiss="modal" data-toggle="modal" data-target="#get-pay-address" className="link link-primary">Make Manual Payment</a></li>
                                </ul>
                                <div className="gaps-2x" />
                                <div className="gaps-1x d-none d-sm-block" />
                                <div className="note note-plane note-light mgb-1x">
                                    <em className="fas fa-info-circle" />
                                    <p className="text-light">You will automatically redirect for payment after your order placing.</p>
                                </div>
                            </div>
                        </div>{/* .modal-content */}
                    </div>{/* .modal-dialog */}
                </div>
                {/* Modal End */}
                {/* not being used right now */}
                <div className="modal fade" id="pay-coingate" tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-md modal-dialog-centered">
                        <div className="modal-content">
                            <div className="popup-body">
                                <h4 className="popup-title">Buy Tokens and Payment</h4>
                                <p className="lead">To receiving <strong>18,750 FGC</strong> tokens including <strong>bonus 1,540 FGC</strong> require payment amount of <strong>1.0 ETH</strong>.</p>
                                <p>You can pay via online payment geteway <strong>“Coingate”</strong>. It’s safe and secure.</p>
                                <div className="pdb-2-5x pdt-1-5x">
                                    <input type="checkbox" className="input-checkbox input-checkbox-md" id="agree-term-2" />
                                    <label htmlFor="agree-term-2">I hereby agree to the <strong>token purchase aggrement &amp; token sale term</strong>.</label>
                                </div>
                                <button className="btn btn-primary" data-dismiss="modal" data-toggle="modal" data-target="#pay-thanks">Place Order &amp; Process to Pay  <em className="ti ti-arrow-right mgl-4-5x" /></button>
                                <div className="gaps-3x" />
                                <div className="note note-plane note-light mgb-1x">
                                    <em className="fas fa-info-circle" />
                                    <p>You will automatically redirect to Coingate website for payment</p>
                                </div>
                            </div>
                        </div>{/* .modal-content */}
                    </div>{/* .modal-dialog */}
                </div>
                {/* Modal End */}
                {/* not being used right now */}
                <div className="modal fade" id="pay-thanks" tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-md modal-dialog-centered">
                        <div className="modal-content">
                            <div className="popup-body text-center">
                                <div className="gaps-2x" />
                                <div className="pay-status pay-status-success">
                                    <em className="ti ti-check" />
                                </div>
                                <div className="gaps-2x" />
                                <h3>Thanks for your contribution!</h3>
                                <p>Your payment amount <strong>1.0 ETH</strong> has been successfully received againest order no. <strong>TNX94KR8N0</strong>. We’ve added <strong>18,750 FGC</strong> tokens in account.</p>
                                <div className="gaps-2x" />
                                <a href="ico-distribution.html" className="btn btn-primary">See Token Balance</a>
                                <div className="gaps-1x" />
                            </div>
                        </div>{/* .modal-content */}
                    </div>{/* .modal-dialog */}
                </div>
                {/* Modal End */}
                <PayReviewModal modalId="pay-review" />
                {/* Modal End */}
                {/* not being used right now */}
                <div className="modal fade" id="pay-failed" tabIndex={-1}>
                    <div className="modal-dialog modal-dialog-md modal-dialog-centered">
                        <div className="modal-content">
                            <div className="popup-body text-center">
                                <div className="gaps-2x" />
                                <div className="pay-status pay-status-error">
                                    <em className="ti ti-alert" />
                                </div>
                                <div className="gaps-2x" />
                                <h3>Oops! Payment failed!</h3>
                                <p>Sorry, seems there is an issues occurred and we couldn’t process your payment. If you continue to have issues, please contact us with order no. <strong>TNX94KR8N0</strong>.</p>
                                <div className="gaps-2x" />
                                <a href="#" data-dismiss="modal" data-toggle="modal" data-target="#get-pay-address" className="btn btn-primary">Try to Pay Again</a>
                                <div className="gaps-1x" />
                            </div>
                        </div>{/* .modal-content */}
                    </div>{/* .modal-dialog */}
                </div>
                {/* Modal End */}
            </div>
        </div>
    )
}


const BuyTokenPage = connect((s: any) => ({
    user: s.auth,
    walletDetails: s.walletDetails,
    exchangeRate: s.exchangeRate,
}), {
    getWalletDetails
})(BuyTokenPageComponent);

export { BuyTokenPage };
