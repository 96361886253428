import axios, { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FormButton } from '../../components/atoms/form-button.atom';
import { FormCheckbox } from '../../components/atoms/form-checkbox.atom';
import { FormInput } from '../../components/atoms/form-input.atom';
import { registerWithEmail, registerWithMobile, verifyMobile } from '../../services/auth.service';
import { toast } from "react-toastify";
import isEmail from "validator/lib/isEmail";
import LoginResponse from "../../types/responses/login-response.type";
import './register.scss';
import { FormSelect } from "../../components/atoms/form-select.atom";
import { connect } from "react-redux";
import { StoreState } from "../../types/store-state.type";
import { getPhoneCodeList, PhoneCode } from "../../services/country-state-city.service";
import { useQuery } from '../../hooks/useQuery.hook';
import {isNameValid, isPasswordValid} from '../../helpers/validation.helper';
import {TermsAndConditionsModal} from "../../components/modals/terms-and-conditions-modal";
import {PrivacyAndPolicyModal} from "../../components/modals/privacy-and-policy-modal";

function RegisterPageComponent({ }: any) {

    // form state
    const [name, setName] = useState("");
    const [isSigningWithMobile, setIsSigningWithMobile] = useState(false);
    const [countryCode, setCountryCode] = useState("+91");
    const [countryCodeList, setCountryCodeList] = useState<PhoneCode[]>([]);
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [tocAccepted, setTocAccepted] = useState(false);
    const navigate = useNavigate();
    const [errors, setErrors] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);

    // state for mobile
    const [isOTPInputVisible, setIsOTPInputVisible] = useState(false);
    const [otp, setOtp] = useState<string>("");
    const [error, setError] = useState("");

    const query = useQuery();
    const referralCode = query.get("ref") ?? "";
    const refSuffix = referralCode ? "?ref=" + referralCode : "";

    useEffect(() => {
        getPhoneCodeList().then(phoneCodeList => {
            setCountryCodeList(phoneCodeList);
        });
    }, []);

    const validateForm = () => {

        let errors = {} as any;
        let isValid = true;

        if (name.trim() === '') {
            errors.name = ['Name is required.']
            isValid = false
        } else if (!isNameValid(name)) {
            errors.name = ['Enter a valid name.']
            isValid = false
        }

        if (isSigningWithMobile) {
            if (countryCode.trim() === '') {
                errors.countryCode = ['Country code is required.']
                isValid = false
            }
            if (mobile.trim() === '') {
                errors.mobile = ['Mobile is required.']
                isValid = false
            }
        } else {
            if (!isEmail(email)) {
                errors.email = ['Please enter a valid email.']
            }
        }

        if (password.trim() === '') {
            errors.password = ['Password is required.']
            isValid = false
        }

        if (!isPasswordValid(password)) {
            errors.password = [
                // 'Please enter a strong Password.',
                `Password should be minimum of 8 letters and with at least a symbol,
                upper and lower case letters and a number`];
            isValid = false
        }

        if (confirmPassword.trim() === '') {
            errors.confirmPassword = ['Confirm password is required.']
            isValid = false
        } else if (password !== confirmPassword) {
            errors.confirmPassword = ["Password's does not match."]
            isValid = false
        }
        if (!tocAccepted) {
            errors.tocAccepted = ["Please accept Privacy Policy and Terms."]
            isValid = false
        }

        setErrors(errors);
        return isValid
    }

    const verifyOTP = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        // verify otp
        if (!(/^\d{4}$/.test(otp))) {
            return setErrors({
                otp: ["Invalid OTP!"]
            });
        } else {
            setErrors({});
        }

        // call api
        setIsLoading(true);
        try {
            const response = await verifyMobile({
                mobile,
                otp
            });
            if (response.message) {
                toast.success(response.message);
                navigate('/register-success');
            }
        } catch (error) {
            setIsLoading(false);
            if (axios.isAxiosError(error)) {
                if (error?.response?.status === 422) {
                    // validation error
                    toast.error("Some validation error occurred");
                    setErrors(error?.response?.data.errors)
                } else {
                    toast.error(error.response?.data.message);
                }
                console.log("Error", error?.response?.data);
            } else {
                toast.error("Something went wrong");
                console.log("Error", error);
            }
        }

    }

    /**
     * Form submit
     * @param e 
     * @returns 
     */
    const submit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const isValid = validateForm();
        if (!isValid) {
            return false
        }
        setIsLoading(true);
        try {
            let response: LoginResponse;
            if (isSigningWithMobile) {
                response = await registerWithMobile({
                    name,
                    mobile,
                    password,
                    countryCode,
                    referralCode,
                });
            } else {
                response = await registerWithEmail({
                    name,
                    email,
                    password,
                    referralCode,
                });
            }
            if (response.message) {
                toast.success(response.message);
                // redirect for email
                if (!isSigningWithMobile) {
                    navigate('/register-success?mode=email');
                } else {
                    // show otp field
                    setIsOTPInputVisible(true);
                    setIsLoading(false);
                }
            }
        } catch (error) {
            setIsLoading(false);
            if (axios.isAxiosError(error)) {
                if (error?.response?.status === 422) {
                    // validation error
                    toast.error("Some validation error occurred");
                    setErrors(error?.response?.data.errors)
                } else {
                    toast.error(error.response?.data.message);
                }
                console.log("Error", error?.response?.data);
            } else {
                toast.error("Something went wrong");
                console.log("Error", error);
            }
        }

    }

    return (
        <div className="page-ath-wrap">
            <div className="page-ath-content">
                <div className="page-ath-header">
                    <a href="../index" className="page-ath-logo">
                        <img src="/images/fgc_logo_2.png" alt="logo" />
                    </a>
                </div>
                <div className="page-ath-form">
                    <h2 className="page-ath-heading">Sign up <small>Create New FGC Account</small></h2>
                    <form>
                        {
                            isOTPInputVisible ? (
                                <FormInput placeholder="4 Digit OTP" value={otp} onChange={setOtp}
                                    errors={errors.otp} />
                            ) : (
                                <>
                                    <FormInput placeholder="Your Name" value={name} onChange={setName}
                                        errors={errors.name} />
                                    <FormCheckbox checked={isSigningWithMobile} label={'Sign up using mobile'}
                                        setChecked={() => setIsSigningWithMobile(!isSigningWithMobile)} />
                                    {isSigningWithMobile ?
                                        <div className="mobile-with-Country-code">
                                            <FormSelect onChange={setCountryCode} options={countryCodeList} value={countryCode}
                                                errors={errors.countryCode} />
                                            <FormInput placeholder="Your Mobile" value={mobile}
                                                onChange={setMobile} errors={errors.mobile} />
                                        </div> :
                                        <FormInput placeholder="Your Email" value={email}
                                            onChange={setEmail} errors={errors.email} />
                                    }
                                    <FormInput placeholder="Password" type="password" value={password} onChange={setPassword}
                                        errors={errors.password} />
                                    <FormInput placeholder="Repeat Password" type="password" value={confirmPassword}
                                        onChange={setConfirmPassword} errors={errors.confirmPassword} />
                                    <div style={{ height: '15px' }}></div>
                                    <FormCheckbox
                                        checked={tocAccepted}
                                        setChecked={setTocAccepted}
                                        label={
                                            <>
                                                I agree to FGC’s <a href="https://footballgoalcoin.com/privacy-policy/" target="_blank">Privacy Policy</a> &amp;
                                                <a href="https://footballgoalcoin.com/terms-of-service/" target="_blank"> Terms of Service.</a>
                                            </>
                                        }
                                        errors={errors.tocAccepted} />
                                </>
                            )
                        }
                        <span style={{ color: "red" }}>{error}</span>
                        <FormButton label={
                            isOTPInputVisible ? "Verify OTP" : (!isSigningWithMobile ? "Create Account" : "Next")
                        } onClick={isOTPInputVisible ? verifyOTP : submit} isLoading={isLoading} />
                    </form>
                    <div className="sap-text"><span>Or Sign Up With</span></div>
                    <ul className="row guttar-20px guttar-vr-20px">
                        <li className="col"><a href="src/pages#"
                            className="btn btn-outline btn-dark btn-facebook btn-block"><em
                                className="fab fa-facebook-f" /><span>Facebook</span></a></li>
                        <li className="col"><a href="src/pages#"
                            className="btn btn-outline btn-dark btn-google btn-block"><em
                                className="fab fa-google" /><span>Google</span></a></li>
                    </ul>
                    <div className="gaps-2x" />
                    <div className="gaps-2x" />
                    <div className="form-note">
                        Already have an account ? <Link to={"/login" + refSuffix}> <strong>Sign in instead</strong></Link>
                    </div>
                </div>
                <div className="page-ath-footer">
                    <ul className="footer-links">
                        <li><a href="https://footballgoalcoin.com/privacy-policy/" target="_blank">Privacy Policy</a></li>
                        <li><a href="https://footballgoalcoin.com/terms-of-service/" target="_blank">Terms of Service</a></li>
                        <li>© 2023 FGC.</li>
                    </ul>
                </div>
            </div>
            <div className="page-ath-gfx">
                <div className="w-100 d-flex justify-content-center">
                    <div className="col-md-8 col-xl-5">
                        {/*<img src="images/ath-gfx.png" alt="image" />*/}
                    </div>
                </div>
            </div>
            <PrivacyAndPolicyModal modalId='privacy-and-policy'/>
            <TermsAndConditionsModal modalId='terms-and-conditions'/>
        </div >
    );
}

const stp = (state: StoreState) => ({});
const dtp = {};

export const RegisterPage = connect(stp, dtp)(RegisterPageComponent)
