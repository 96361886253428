import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FacebookLogin } from '../../components/auth/facebook-login.button';
import { GoogleLogin } from '../../components/auth/google-login.button';
import { httpPost } from "../../services/http.service";
import isEmail from 'validator/lib/isEmail';
import { toast } from "react-toastify";
import axios, { AxiosResponse } from "axios";
import { Spinner } from "../../components/spinner";
import { connect } from "react-redux";
import { StoreState } from "../../types/store-state.type";
import { createSession } from "../../store/auth.store";
import { FormInput } from '../../components/atoms/form-input.atom';
import { FormButton } from '../../components/atoms/form-button.atom';
import { FormSelect } from '../../components/atoms/form-select.atom';
import { FormCheckbox } from '../../components/atoms/form-checkbox.atom';
import LoginResponse from '../../types/responses/login-response.type';
import { loginWithEmail, loginWithMobile, registerWithMobile } from '../../services/auth.service';
import moment from "moment";
import { getPhoneCodeList, PhoneCode } from "../../services/country-state-city.service";
import { useQuery } from '../../hooks/useQuery.hook';

function LoginPageComponent({
    setUser
}: any) {

    const [email, setEmail] = useState("");
    const [countryCode, setCountryCode] = useState("+91");
    const [countryCodeList, setCountryCodeList] = useState<PhoneCode[]>([]);
    const [mobile, setMobile] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const [errors, setErrors] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [useMobileInstead, setUseMobileInstead] = useState(false);

    const query = useQuery();
    const referralCode = query.get("ref");
    const refSuffix = referralCode ? "?ref=" + referralCode : "";

    useEffect(() => {
        getPhoneCodeList().then(phoneCodeList => {
            setCountryCodeList(phoneCodeList);
        });
    }, []);

    function validateAll() {
        const errors = {} as any;
        let isValid = true;
        if (useMobileInstead) {
            if (countryCode.trim() === '') {
                errors.countryCode = ['Country code is required.']
                isValid = false
            }
            if (mobile.trim() === '') {
                errors.mobile = ['Mobile is required.']
                isValid = false
            }
        } else {
            if (email.trim() === '') {
                errors.email = ['Email is required.']
                isValid = false
            } else if (!isEmail(email)) {
                errors.email = ['Please enter a valid email.']
                isValid = false
            }
        }

        if (password.trim() === '') {
            errors.password = ['Password is required.']
            isValid = false
        }
        setErrors(errors)
        return isValid
    }

    const login = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        const isValid = validateAll()
        if (!isValid) {
            return false
        }
        setIsLoading(true);
        try {
            let response: LoginResponse;
            if (useMobileInstead) {
                response = await loginWithMobile({
                    mobile,
                    password,
                    countryCode
                });
            } else {
                response = await loginWithEmail({
                    email,
                    password
                });
            }
            if (response.message) {
                // all correct, start session
                toast.success(response.message);
                const userObject = {
                    ...response.user,
                    token: (response as any).token,
                };
                setUser(userObject);
                localStorage.setItem('fgc.user', JSON.stringify(userObject));
                localStorage.setItem('fgc.userSession', JSON.stringify({ session: 'active', lastActive: moment() }));
                navigate('/dashboard')
            }
        } catch (error: any) {
            setIsLoading(false);
            if (axios.isAxiosError(error)) {
                if (error?.response?.status === 422) {
                    // validation error
                    toast.error("Some validation error occurred");
                    setErrors(error?.response?.data.errors);
                } else {
                    toast.error(error.response?.data.message);
                }
                console.log("Error", error?.response?.data);
            } else {
                                toast.error("Something went wrong");
                console.log("Error", error);
            }
        }
    }

    return (
        <div className="ICOApp">
            <div className="page-ath-wrap">
                <div className="page-ath-content">
                    <div className="page-ath-header">
                        <a href="./" className="page-ath-logo">
                            <img src="/images/fgc_logo_2.png" alt="logo" />
                        </a>
                    </div>
                    <div className="page-ath-form">
                        <h2 className="page-ath-heading">Sign in <small>to your FGC Account</small></h2>
                        <form onSubmit={login}>
                            {/* Email or mobile */}
                            {useMobileInstead ?
                                <div className="mobile-with-Country-code">
                                    <FormSelect onChange={setCountryCode} options={countryCodeList} value={countryCode}
                                        errors={errors.countryCode} />
                                    <FormInput placeholder="Your Mobile" value={mobile}
                                        onChange={setMobile} errors={errors.mobile} />
                                </div> :
                                <FormInput placeholder="Your Email" value={email}
                                    onChange={setEmail} errors={errors.email} />
                            }
                            {/* auth switcher */}
                            <FormCheckbox checked={useMobileInstead} label={'Sign in using mobile'}
                                setChecked={() => setUseMobileInstead(!useMobileInstead)} />
                            {/* password */}
                            <FormInput placeholder="Password" type="password" value={password} onChange={setPassword}
                                errors={errors.password} />
                            <div className="d-flex justify-content-between align-items-center">
                                {/* <div className="input-item text-left">
                                    <input className="input-checkbox input-checkbox-md" id="remember-me"
                                        type="checkbox" />
                                    <label htmlFor="remember-me">Remember Me</label>
                                </div> */}
                                <div>
                                    <Link to="/forgot-password">Forgot password?</Link>
                                    <div className="gaps-2x" />
                                </div>
                            </div>
                            <FormButton label="Sign In" onClick={login} isLoading={isLoading} />
                        </form>
                        <div className="sap-text"><span>Or Sign up With</span></div>
                        <ul className="row guttar-20px guttar-vr-20px">
                            <li className="col"><FacebookLogin /></li>
                            <li className="col"><GoogleLogin /></li>
                        </ul>
                        <div className="gaps-2x" />
                        <div className="gaps-2x" />
                        <div className="form-note">
                            Don’t have an account? <Link to={"/register" + refSuffix}> <strong>Sign up here</strong></Link>
                        </div>
                    </div>
                    <div className="page-ath-footer">
                        <ul className="footer-links">
                            <li><a href="https://footballgoalcoin.com/privacy-policy/" target="_blank">Privacy Policy</a></li>
                            <li><a href="https://footballgoalcoin.com/terms-of-service/" target="_blank">Terms of Service</a></li>
                            <li>© 2023 FGC.</li>
                        </ul>
                    </div>
                </div>
                <div className="page-ath-gfx">
                    <div className="w-100 d-flex justify-content-center">
                        <div className="col-md-8 col-xl-5">
                            {/*<img src="images/ath-gfx.png" alt="image" />*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const dtp = {
    setUser: createSession
}

export const LoginPage = connect(() => ({}), dtp)(LoginPageComponent);
